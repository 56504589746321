import React from 'react';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Link } from 'gatsby';
import GlobalLayout from './GlobalLayout';
import PostLabels from './PostLabels';
import Seo from './seo';
import TileList from './TileList';
import LinkCard from './LinkCard';
import MediaGrid from './MediaGrid';
import InlineVideo from './InlineVideo';

const shortcodes = { Link, MediaGrid, InlineVideo }; // Provide common components here

export default function PageTemplate({ data }) {
  return (
    <GlobalLayout>
      <Seo
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
      />
      <div className="o-page-pad--btm-xl">
        <PostLabels
          items={[
            {
              label: 'Date',
              text: data.mdx.frontmatter.date,
            },
            {
              label: 'Agency',
              text: data.mdx.frontmatter.agency,
            },
            {
              label: 'Role',
              text: data.mdx.frontmatter.role,
            },
            {
              label: 'Tech',
              text: data.mdx.frontmatter.tech,
            },
          ]}
        />
        <div className="c-post-body">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>

      <section className="o-page-pad--xl u-bk-off-black">
        <TileList header="Browse More" id="more-posts">
          {data.allMdx &&
            data.allMdx.edges
              .filter(
                edge =>
                  edge.node.frontmatter.title !== data.mdx.frontmatter.title
              )
              .map(edge => (
                <LinkCard
                  isSmall={true}
                  url={edge.node.frontmatter.path}
                  externalLink={edge.node.frontmatter.external}
                  title={edge.node.frontmatter.title}
                  description={edge.node.frontmatter.description}
                  date={edge.node.frontmatter.date}
                  tags={edge.node.frontmatter.tags}
                  imageAlt={edge.node.frontmatter.featuredImageAlt}
                  imgData={
                    edge.node.frontmatter.featuredImage &&
                    edge.node.frontmatter.featuredImage.childImageSharp.fluid
                  }
                  key={edge.node.id}
                />
              ))}
        </TileList>
      </section>
    </GlobalLayout>
  );
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String, $type: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        path
        title
        type
        date(formatString: "MM.DD.YYYY")
        description
        tags
        tech
        role
        agency
      }
    }

    allMdx(
      filter: { frontmatter: { type: { eq: $type } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            type
            date(formatString: "MM.DD.YYYY")
            description
            tags
            external
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            featuredImageAlt
          }
        }
      }
    }
  }
`;
