import React from 'react';
import './MediaGrid.scss';

const MediaGrid = props => (
  <div
    className={`c-media-grid 
    ${props.column ? 'c-media-grid--column ' : ''}${
      props.full ? 'c-media-grid--full ' : ''
    }${props.xlXs ? 'c-media-grid--xlXs ' : ''}${
      props.lgSm ? 'c-media-grid--lgSm ' : ''
    }${props.three ? 'c-media-grid--three ' : ''}${
      props.two ? 'c-media-grid--two ' : ''
    }${props.four ? 'c-media-grid--four ' : ''}`}
  >
    {props.children}
  </div>
);

export default MediaGrid;
