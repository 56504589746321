import React from 'react';
import './PostLabels.scss';

const PostLabels = ({ items }) => (
  <>
    <h2 className="u-sr-only" id="post-info">
      Post Informtion
    </h2>
    <ul className="c-post-labels" aria-labelledby="post-info">
      {items.map(
        (item, i) =>
          item.text && (
            <li key={`label${item.label}`}>
              <span className="f-label">{item.label}</span> <br />{' '}
              <span className="f-label-text">{item.text}</span>
            </li>
          )
      )}
    </ul>
  </>
);

export default PostLabels;
